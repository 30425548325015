/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// UI lib comoponents
import { Col, Row } from 'react-grid-system';

// Local UI components
import DynamicImage from '../../../shared/UIKit/DynamicImage';

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';
import PickProject from '../PickProject';
import PickCountries from '../PickCountries';
import Recap from '../Recap';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Main() {
  /* ******************************** HOOKS ******************************* */
  const [selectedProject, setSelectedProject] = useState({
    project: { variation: 'primary' },
    countries: undefined,
    step: 1,
  });

  // localization
  const { t } = useTranslation();
  const { customTitle } = t('pickProject', { returnObjects: true });

  function Title() {
    if (selectedProject?.project?.variation === 'primary') {
      return customTitle?.primary?.map(
        ({ id, value, highlighted = false, step }) => {
          if (step.includes(selectedProject.step)) {
            let currentValue;
            if (selectedProject.step === 1) {
              currentValue = value;
            } else if (highlighted) {
              currentValue = selectedProject?.project?.name;
            } else {
              currentValue = value;
            }
            return (
              <span key={id} className={`${highlighted ? 'highlighted' : ''}`}>
                {currentValue}
              </span>
            );
          }

          return null;
        },
      );
    }

    if (selectedProject?.project?.variation === 'secondary') {
      return customTitle?.secondary?.map(
        ({ id, value, highlighted = false, step }) => {
          if (step.includes(selectedProject.step)) {
            let currentValue;
            if (selectedProject.step === 1) {
              currentValue = value;
            } else if (highlighted) {
              currentValue = selectedProject?.project?.name;
            } else {
              currentValue = value;
            }
            return (
              <span key={id} className={`${highlighted ? 'highlighted' : ''}`}>
                {currentValue}
              </span>
            );
          }

          return null;
        },
      );
    }

    return null;
  }

  function Step() {
    if (selectedProject?.step === 1) {
      return (
        <PickProject
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
        />
      );
    }
    if (selectedProject?.step === 2) {
      return (
        <PickCountries
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
        />
      );
    }

    if (selectedProject?.step === 3) {
      const countries = selectedProject?.countries;
      return (
        <Recap
          sourceCountry={countries.buyerCountry}
          targetCountry={countries.sellerCountry || countries.installCountry}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
        />
      );
    }

    return null;
  }
  /* ******************************** RENDERING ******************************* */
  // if (selectedProject?.step === 4) {
  //   return (
  //     <div>
  //       <MyPDF
  //         sourceCountry={selectedProject.countries.buyerCountry}
  //         targetCountry={selectedProject.countries.sellerCountry}
  //       />
  //     </div>
  //   );
  // }

  return (
    <div className="project-main">
      <Row justify="start" nogutter>
        {selectedProject?.step !== 4 && (
          <Col sm={12} lg={6} className="project-main-left-col">
            <div className="project-main__img-container">
              <DynamicImage
                path="selectProjectCountries"
                fileName="side-img.jpg"
                alt=""
                className="project-main__img"
              />
            </div>
            <h1 className="project-main__title">
              <Title />
            </h1>
          </Col>
        )}
        <Col
          sm={12}
          lg={selectedProject?.step !== 4 ? 6 : 12}
          className="project-main-right-col"
        >
          <Step />
        </Col>
      </Row>
    </div>
  );
}

Main.propTypes = {};

Main.defaultProps = {};

export default Main;
