/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents
import { Text, View } from '@react-pdf/renderer';
import PDFSubtitle from './PDFSubtitle';
import TradeAgreementDetails from './TradeAgreementDetails';

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style

// Assets
import icon1 from '../../../images/pdf/icon1.png';
import statusImg from '../../../images/pdf/status.png';
import calenderImg from '../../../images/pdf/calendar.png';
import handshakeImg from '../../../images/pdf/handshake.png';
import surfaceImg from '../../../images/pdf/surface.png';
import { formatDate } from '../../Helpers/date';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */
function TradeAgreements({ styles, data, target, source }) {
  /* ******************************** RENDERING ******************************* */
  return (
    <>
      <View
        style={{
          ...styles.w100,
          ...styles.flexCenter,

          marginBottom: '5px',
          marginTop: '20px',
        }}
      >
        <PDFSubtitle
          styles={styles}
          title={`Accords commerciaux entre ${target} ${
            source ? `et ${source}` : ''
          }`}
          titleWidth={305}
          size={14}
        />
      </View>
      <View
        style={{
          ...styles.flexCenter,
          justifyContent: 'space-between',
          marginBottom: 10,
          flexWrap: 'wrap',
          width: '70%',
          margin: '0 auto 10px',
        }}
      >
        <TradeAgreementDetails
          styles={styles}
          label="Nom de l'accord"
          value={data?.nom}
          img={handshakeImg}
        />
        <TradeAgreementDetails
          styles={styles}
          label="Statut actuel"
          value={data?.statut}
          img={statusImg}
        />
        <TradeAgreementDetails
          styles={styles}
          label="Type"
          value={data?.type}
          img={icon1}
        />
        <TradeAgreementDetails
          styles={styles}
          label="Date de signature"
          value={formatDate(data?.date_de_signature)}
          img={calenderImg}
        />
        <TradeAgreementDetails
          styles={styles}
          label="Couverture"
          value={data?.couverture}
          img={surfaceImg}
        />
        <TradeAgreementDetails
          styles={styles}
          label="Date d'entrée en force"
          value={formatDate(data?.date_entree_en_force)}
          img={calenderImg}
        />
      </View>
      {!data?.nom && (
        <>
          <Text
            style={{
              color: '#FF3C3C',
              ...styles.textCenter,
              ...styles.size(9),
            }}
          >
            Si la convention n&apos;existe pas, dans &quot;signature&quot; on
            affiche &quot;NA&quot; et les autres lignes disparaissent
          </Text>
          <Text
            style={{
              color: '#FF3C3C',
              ...styles.textCenter,
              ...styles.size(9),
              marginTop: 5,
            }}
          >
            S&apos;il existe plusieurs conventions, on dupluque les lignes
          </Text>
        </>
      )}
    </>
  );
}

TradeAgreements.propTypes = {
  styles: PropTypes.objectOf(PropTypes.shape).isRequired,
  data: PropTypes.objectOf(PropTypes.shape).isRequired,
  target: PropTypes.string,
  source: PropTypes.string,
};

TradeAgreements.defaultProps = {
  target: undefined,
  source: undefined,
};

export default TradeAgreements;
