/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents
import { Image, Text, View } from '@react-pdf/renderer';

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style

// Assets

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */
function TradeAgreementDetails({ styles, label, img, value }) {
  /* ******************************** RENDERING ******************************* */
  return (
    <View
      style={{
        width: '40%',
        ...styles.flexCenter,
        justifyContent: 'flex-start',
        marginBottom: 20,
      }}
    >
      <Image src={img} style={{ width: 40, marginRight: 5 }} />
      <View styles={{ ...styles.flexCol }}>
        <Text style={{ color: '#89888F', ...styles.size(12) }}>
          {label ?? 'NA'}
        </Text>
        <Text
          style={{
            ...styles.darkText,
            ...styles.size(13),
            ...styles.bold,
            marginTop: 5,
          }}
        >
          {value ?? 'NA'}
        </Text>
      </View>
    </View>
  );
}

TradeAgreementDetails.propTypes = {
  styles: PropTypes.objectOf(PropTypes.shape).isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  img: PropTypes.node.isRequired,
};

TradeAgreementDetails.defaultProps = {
  value: 'NA',
};

export default TradeAgreementDetails;
