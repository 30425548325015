/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents

// Local UI components
import PDFTemplate from './PDFTemplate';
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style

// Assets

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */
function MyPDF({ sheetData }) {
  if (typeof window === 'undefined') {
    return null;
  }
  return sheetData && <PDFTemplate sheetData={sheetData?.attributes} />;
}

MyPDF.propTypes = {
  sheetData: PropTypes.objectOf(PropTypes.shape).isRequired,
};

export default MyPDF;
