/* eslint-disable operator-linebreak */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';

// Local UI components
import PDFHeader from './PDFHeader';
import PDFTable from './PDFTable';

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style

// Assets
import boldFont from '../../../fonts/GraphikBold.otf';
import regularFont from '../../../fonts/GraphikRegular.otf';
import mediumFont from '../../../fonts/GraphikMedium.otf';
import semiBoldFont from '../../../fonts/GraphikSemibold.otf';
import populationImg from '../../../images/pdf/population.png';
import surfaceImg from '../../../images/pdf/surface.png';
// import icon3 from '../../../images/pdf/icon3.png';
import map from '../../../images/maptn.png';
import PDFFooter from './PDFFooter';
import TaxInvestmentAgreements from './TaxInvestmentAgreements';
import TradeAgreements from './TradeAgreements';

Font.register({ family: 'MyFontRegular', src: regularFont });
Font.register({ family: 'MyFontBold', src: boldFont, fontWeight: 'bold' });
Font.register({
  family: 'MyFontMedium',
  src: mediumFont,
});
Font.register({
  family: 'MyFontSemiBold',
  src: semiBoldFont,
});
// Define custom styles for the PDF
const styles = StyleSheet.create({
  page: {
    padding: '10px 10px 30px',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    maxHeight: 100,
    display: 'flex',
    flexWrap: 'nowrap',
    flexGrow: 1,
    padding: '10px 15px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#141320',
  },
  whiteText: {
    color: '#FFF',
  },
  darkText: {
    color: '#141320',
  },
  yellowText: {
    color: '#fec864',
  },
  yellowBg: {
    backgroundColor: '#fec864',
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  py15: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  py30: {
    paddingTop: 30,
    paddingBottom: 30,
  },
  p10: {
    padding: 10,
  },
  flexRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  flexCenter: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  logo: {
    objectFit: 'contain',
  },
  w30: {
    width: '30%',
  },
  w10: {
    width: '10%',
  },
  w15: {
    width: '15%',
  },
  w40: {
    width: '40%',
  },
  w50: {
    width: '50%',
  },
  w60: {
    width: '60%',
  },
  w100: {
    width: '100%',
  },
  f32: {
    fontSize: 32,
  },
  size: (size) => ({
    fontSize: `${size}px`,
  }),
  bold: {
    fontFamily: 'MyFontBold',
  },
  medium: {
    fontFamily: 'MyFontMedium',
  },
  semibold: {
    fontFamily: 'MyFontSemiBold',
  },
  normal: {
    fontFamily: 'MyFontRegular',
  },
  borderBottom: {
    borderBottom: '1px solid #DDDDDD',
    marginBottom: 20,
  },
  textCenter: {
    textAlign: 'center',
  },
});

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */
function PDFTemplate({ sheetData }) {
  /* ******************************** RENDERING ******************************* */
  function getEconomyTable() {
    const table = sheetData?.economie?.sort((a, b) => +a?.annee - +b?.annee);

    const headers = table?.map((el) => el.annee);
    const rows = [
      ['PIB ($ US courants)', ...table?.map((el) => el?.pib)],
      [
        'PIB par habitant ($ US courants)',
        ...table?.map((el) => el?.pib_par_habitant),
      ],
      [
        'Croissance du PIB (% annuel)',
        ...table?.map((el) => el?.croissance_du_pib),
      ],
      [
        'Inflation, prix à la consommation (% annuel)',
        ...table?.map((el) => el?.inflation),
      ],
      [
        "Taux d'imposition total (% des bénéfices commerciaux)",
        ...table?.map((el) => el?.taux_imposition_total),
      ],
      ['Population par année', ...table?.map((el) => el?.population_par_annee)],
    ];
    return { headers, rows };
  }
  const economyTable = getEconomyTable();

  function getInstitutionnelTable() {
    const table = sheetData?.institutionnel?.sort(
      (a, b) => +a?.annee - +b?.annee,
    );

    const headers = table?.map((el) => el?.annee);
    const rows = [
      [
        'Stabilité politique et absence de violence/terrorisme : estimation',
        ...table?.map((el) => el?.stabilite_politique),
      ],
      [
        'Indice de la facilité de faire des affaires (1=réglementations les plus favorables aux entreprises)',
        ...table?.map((el) => el?.indice_de_la_facilite_de_faire_des_affaires),
      ],
      ['Etat de droit', ...table?.map((el) => el?.etat_de_droit)],
      [
        'Contrôle de la corruption (estimation)',
        ...table?.map((el) => el?.controle_corruption),
      ],
    ];
    return { headers, rows };
  }
  const institutionnelTable = getInstitutionnelTable();

  const { accords_fiscaux_investissement, accords_commerciaux } = sheetData;

  const sourceCountry =
    sheetData?.paysSource?.data?.attributes?.country?.data?.attributes
      ?.name_fr ?? '';
  const targetCountry =
    sheetData?.paysCible?.data?.attributes?.country?.data?.attributes
      ?.name_fr ?? '';

  return (
    <Document title={`${sourceCountry}_${targetCountry}.pdf`}>
      <Page size="A4">
        <View style={styles.page}>
          <PDFHeader
            styles={styles}
            name={targetCountry}
            sourceName={sourceCountry}
          />
          <View
            style={{
              ...styles.flexRow,
              ...styles.w100,
              ...styles.py30,
              ...styles.borderBottom,
            }}
          >
            <View style={{ ...styles.w50, ...styles.flexCol }}>
              <Text style={{ ...styles.size(12), ...styles.medium }}>
                Informations générales
              </Text>

              <View style={{ ...styles.flexRow, marginTop: '20px' }}>
                <Image
                  src={populationImg}
                  style={{ width: 45, marginRight: 10 }}
                />
                <View style={styles.flexCol}>
                  <Text style={{ ...styles.darkText, ...styles.size(14) }}>
                    Population totale
                  </Text>
                  <Text
                    style={{
                      ...styles.bold,
                      ...styles.darkText,
                      ...styles.size(18),
                      marginTop: 5,
                    }}
                  >
                    {Number(sheetData?.population)
                      ?.toLocaleString('fr-FR', {
                        useGrouping: true,
                      })
                      .replace(/(\u{202F})/gu, '\u00A0')}
                    <Text
                      style={{
                        ...styles.darkText,
                        ...styles.normal,
                        ...styles.size(14),
                      }}
                    >
                      {`${
                        sheetData?.populationAnnee
                          ? `(${sheetData?.populationAnnee})`
                          : ''
                      }`}
                      {/* {` (${sheetData?.populationAnnee || ''})`} */}
                    </Text>
                  </Text>
                </View>
              </View>
              <View style={{ ...styles.flexRow, marginTop: '20px' }}>
                <Image
                  src={surfaceImg}
                  style={{ width: 45, marginRight: 10 }}
                />
                <View style={styles.flexCol}>
                  <Text style={{ ...styles.darkText, ...styles.size(14) }}>
                    Superficie
                  </Text>
                  <Text
                    style={{
                      ...styles.bold,
                      ...styles.darkText,
                      ...styles.size(18),
                      marginTop: 5,
                    }}
                  >
                    {sheetData?.surface}
                  </Text>
                </View>
              </View>
            </View>
            <View style={{ ...styles.flexCol, ...styles.w50 }}>
              <Image src={map} />
            </View>
          </View>
          <PDFTable
            titleWidth={90}
            title="Economie"
            styles={styles}
            table={economyTable}
          />
          <View style={{ marginTop: '20' }}>
            <PDFTable
              titleWidth={120}
              title="Institutionnel"
              styles={styles}
              table={institutionnelTable}
            />
          </View>
        </View>
        <PDFFooter page={1} pages={2} styles={styles} />
      </Page>
      <Page size="A4">
        <View style={styles.page}>
          <PDFHeader
            styles={styles}
            name={targetCountry}
            sourceName={sourceCountry}
          />

          <TaxInvestmentAgreements
            target={targetCountry}
            source={sourceCountry}
            data={accords_fiscaux_investissement}
            styles={styles}
          />
          <TradeAgreements
            styles={styles}
            data={accords_commerciaux}
            target={targetCountry}
            source={sourceCountry}
          />
        </View>

        <PDFFooter page={2} pages={2} styles={styles} />
      </Page>
    </Document>
  );
}
PDFTemplate.propTypes = {
  sheetData: PropTypes.objectOf(PropTypes.shape).isRequired,
};

PDFTemplate.defaultProps = {};

export default PDFTemplate;
