/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useI18next } from 'gatsby-plugin-react-i18next';
import axios from 'axios';
// UI lib comoponents

// Local UI components
import Select from '../../../shared/UIKit/Select';

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
/* eslint-disable*/
import './index.scss';
import { baseUrl } from '../../../shared/ApIs';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function PickCountries({ selectedProject, setSelectedProject }) {
  /* ******************************** HOOKS ******************************* */
  const [wasSubmitted, setWasSubmitted] = useState(false);

  // localization
  const { t } = useTranslation();
  const {
    description,
    form: { primaryInputs, secondaryInputs, buttons },
  } = t('pickCountries', { returnObjects: true });

  /* ******************************** CONSTANTS ******************************* */

  /* ******************************** VARIABLES ******************************* */
  let countries = {};

  /* ******************************** CALLBACKS ******************************* */
  function addSelectedCountry(country) {
    countries = { ...countries, ...country };
  }

  function handleClick(e, cbType) {
    e.preventDefault();
    if (cbType === 'next') {
      if (selectedProject?.project?.variation === 'primary') {
        if (countries?.sellerCountry === undefined) {
          setWasSubmitted(true);
          return;
        }
        if (countries?.buyerCountry === undefined) {
          setWasSubmitted(true);
          return;
        }
      }

      if (selectedProject?.project?.variation === 'secondary') {
        if (countries?.installCountry === undefined) {
          setWasSubmitted(true);
          return;
        }
      }

      setSelectedProject({ ...selectedProject, countries, step: 3 });
    } else if (cbType === 'back') {
      setSelectedProject({ ...selectedProject, step: 1 });
    }
  }

  /* ******************************** RENDER HELPERS ******************************* */

  function Buttons() {
    return buttons.map(({ id, type, value, variation, cbType }) => (
      <button
        key={id}
        type={type === 'button' ? 'button' : 'submit'}
        className={`pick-countries-form__button ${variation}`}
        onClick={(e) => handleClick(e, cbType)}
      >
        {value}
      </button>
    ));
  }

  /* ******************************** RENDERING ******************************* */
  const { language } = useI18next();
  const locale = language === undefined ? 'fr' : language;
  // state
  const [targetCountriesData, setTargetCountriesData] = useState([]);
  const [sourceCountriesData, setSourceCountriesData] = useState([]);

  // Effect
  async function getCountries() {
    try {
      const { data: sourceData } = await axios(
        `${baseUrl}/source-countries?pagination[pageSize]=300&populate=country.flag`,
      );
      const { data: targetData } = await axios(
        `${baseUrl}/target-countries?pagination[pageSize]=300&populate=country.flag`,
      );
      setTargetCountriesData(targetData?.data);
      setSourceCountriesData(sourceData?.data);
    } catch (error) {
      console.log(error);
    }
  }

  function flattenData(data) {
    return data.map((item) => ({
      label: item?.attributes?.country?.data?.attributes[`name_${locale}`],
      value: item?.id,
    }));
  }

  useEffect(() => {
    getCountries();
  }, []);
  // Get Options
  return (
    <div className="pick-countries">
      <p className="pick-countries__description">{description}</p>
      <form className="pick-countries-form">
        {primaryInputs?.map(
          (
            { id, type, label, name, inputId, placeholder, fieldErrorMessage },
            index,
          ) => {
            if (selectedProject?.project?.variation !== 'primary') return null;
            return (
              <div key={id} className="pick-countries-form-item">
                {type === 'select' ? (
                  <Select
                    inputId={inputId}
                    label={label}
                    name={name}
                    placeholder={placeholder}
                    options={flattenData(
                      index === 1 ? sourceCountriesData : targetCountriesData,
                    )}
                    hasCustomFunction
                    addSelectedObject={(country) => {
                      addSelectedCountry(country);
                    }}
                    wasSubmitted={wasSubmitted}
                    fieldErrorMessage={fieldErrorMessage}
                  />
                ) : null}
              </div>
            );
          },
        )}
        {secondaryInputs?.map(
          ({
            id,
            type,
            label,
            name,
            inputId,
            placeholder,
            fieldErrorMessage,
          }) => {
            if (selectedProject?.project?.variation !== 'secondary') {
              return null;
            }
            return (
              <div key={id} className="pick-countries-form-item">
                {type === 'select' ? (
                  <Select
                    inputId={inputId}
                    label={label}
                    name={name}
                    placeholder={placeholder}
                    hasCustomFunction
                    options={flattenData(targetCountriesData)}
                    addSelectedObject={(country) => {
                      addSelectedCountry(country);
                    }}
                    wasSubmitted={wasSubmitted}
                    fieldErrorMessage={fieldErrorMessage}
                  />
                ) : null}
              </div>
            );
          },
        )}
        <div className="pick-countries-buttons-container">
          <Buttons />
        </div>
      </form>
    </div>
  );
}

PickCountries.propTypes = {
  selectedProject: PropTypes.objectOf(
    PropTypes.shape,
    PropTypes.shape,
    PropTypes.number,
  ).isRequired,
  setSelectedProject: PropTypes.func.isRequired,
};

PickCountries.defaultProps = {};

export default PickCountries;
