/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents
import { Image, Text, View } from '@react-pdf/renderer';

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style

// Assets
import statusImg from '../../../images/pdf/status.png';
import calenderImg from '../../../images/pdf/calendar.png';
import PDFSubtitle from './PDFSubtitle';
import { formatDate } from '../../Helpers/date';
import PDFTable from './PDFTable';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */
function TaxInvestmentAgreements({ styles, data, target, source }) {
  /* ******************************** RENDERING ******************************* */
  let showImmpositionMessage = false;
  if (!data?.type || !data?.statut || !data?.date_de_signature) {
    showImmpositionMessage = true;
  }

  function table() {
    const rows = [
      [
        "Installations pour livraisons exclues de l'ES",
        data?.installations_pour_livraisons_exclues_de_l_ES,
      ],
      [
        "Durée de l'ES pour de la construction (Months)",
        data?.duree_de_l_ES_pour_de_la_construction,
      ],
      ['Taux de RAS sur les intérêts (%)', data?.taux_de_RAS_sur_les_interets],
      [
        "Durée de l'ES pour des services (Months)",
        data?.duree_de_l_ES_pour_des_services,
      ],
      [
        'Taux de RAS sur les royalties (%)',
        data?.taux_de_RAS_sur_les_royalties,
      ],
      [
        'Taux de RAS sur les royalties (%)',
        data?.taux_de_RAS_sur_les_royalties,
      ],
      [
        'Taux de RAS sur les frais de services techniques (%)',
        data?.taux_de_RAS_sur_les_frais_de_services_techniques,
      ],
      [
        'Taux de RAS sur les dividendes éligibles (%)',
        data?.taux_de_RAS_sur_les_dividendes_eligibles,
      ],
      [
        'Imposition à la source des autres revenus',
        data?.imposition_a_la_source_des_autres_revenus,
      ],
    ];

    return { rows };
  }

  return (
    <>
      <View
        style={{
          ...styles.w100,
          ...styles.flexCenter,
          // marginBottom: '',
          marginTop: '20px',
        }}
      >
        <PDFSubtitle
          styles={styles}
          title={`Convention de non double imposition entre ${target} ${
            source ? `et ${source}` : ''
          }`}
          titleWidth={405}
          size={14}
        />
      </View>
      <View
        style={{
          ...styles.flexRow,
          flexWrap: 'wrap',
          marginTop: '-10px',
          marginBottom: '20px',
        }}
      >
        <PDFTable titleWidth={90} title="" styles={styles} table={table()} />
      </View>
      {showImmpositionMessage && (
        <Text
          style={{
            color: '#FF3C3C',
            ...styles.textCenter,
            ...styles.size(9),
          }}
        >
          Si la convention n&apos;existe pas, dans &quot;type&quot; on affiche
          &quot;NA&quot; et les autres lignes disparaissent
        </Text>
      )}
      <View
        style={{
          ...styles.w100,
          ...styles.flexCenter,
          marginBottom: '10px',
          marginTop: '20px',
        }}
      >
        <PDFSubtitle
          styles={styles}
          title={`Conventions d'investissement entre ${target} ${
            source ? `et ${source}` : ''
          }`}
          titleWidth={405}
          size={14}
        />
      </View>
      <View
        style={{
          ...styles.flexCenter,
          justifyContent: 'center',
          marginBottom: 15,
        }}
      >
        <View
          style={{
            ...styles.flexCenter,
            justifyContent: 'space-between',
            // marginBottom: 10,
            flexWrap: 'wrap',
            width: '70%',
            margin: '0 auto 10px',
          }}
        >
          <View style={{ width: '33.33%', ...styles.flexCenter }}>
            {data?.date_de_signature && (
              <>
                <Image src={statusImg} style={{ width: 40, marginRight: 5 }} />
                <View styles={{ ...styles.flexCol }}>
                  <Text style={{ color: '#89888F', ...styles.size(12) }}>
                    Date de signature
                  </Text>
                  <Text
                    style={{
                      ...styles.darkText,
                      ...styles.size(13),
                      ...styles.bold,
                      marginTop: 5,
                    }}
                  >
                    {formatDate(data.date_de_signature) ?? 'NA'}
                  </Text>
                </View>
              </>
            )}
          </View>

          <View
            style={{
              width: '33.33%',
              ...styles.flexCenter,
              marginBottom: '20px',
            }}
          >
            {data?.date_entree_en_force && (
              <>
                <Image
                  src={calenderImg}
                  style={{ width: 40, marginRight: 5 }}
                />
                <View styles={{ ...styles.flexCol }}>
                  <Text style={{ color: '#89888F', ...styles.size(12) }}>
                    Date d&apos;entrée en force
                  </Text>
                  <Text
                    style={{
                      ...styles.darkText,
                      ...styles.size(13),
                      ...styles.bold,
                      marginTop: 5,
                    }}
                  >
                    {formatDate(data.date_entree_en_force) ?? 'NA'}
                  </Text>
                </View>
              </>
            )}
          </View>
          {showImmpositionMessage && (
            <Text
              style={{
                color: '#FF3C3C',
                ...styles.textCenter,
                ...styles.size(9),
              }}
            >
              Si la convention n&apos;existe pas, dans &quot;signature&quot; on
              affiche &quot;NA&quot; et les autres lignes disparaissent
            </Text>
          )}
        </View>
      </View>
    </>
  );
}

TaxInvestmentAgreements.propTypes = {
  styles: PropTypes.objectOf(PropTypes.shape).isRequired,
  data: PropTypes.objectOf(PropTypes.shape).isRequired,
  target: PropTypes.string,
  source: PropTypes.string,
};

TaxInvestmentAgreements.defaultProps = {
  target: undefined,
  source: undefined,
};

export default TaxInvestmentAgreements;
