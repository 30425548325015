/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents
import { Text, View } from '@react-pdf/renderer';

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style

// Assets

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */
function PDFSubtitle({ styles, title, titleWidth, size }) {
  /* ******************************** RENDERING ******************************* */
  return (
    <View style={{ position: 'relative' }}>
      <Text
        style={{
          ...styles.textCenter,
          ...styles.semibold,
          ...styles.size(size),
          marginBottom: '20px',
        }}
      >
        {title}
      </Text>
      <View
        style={{
          position: 'absolute',
          bottom: '55%',
          left: '50%',
          width: titleWidth,
          height: '20%',
          backgroundColor: '#FEC864',
          transform: `translateX(-${titleWidth / 2}px)`,
          zIndex: -1,
        }}
      >
        {/* <Text>test</Text> */}
      </View>
    </View>
  );
}

PDFSubtitle.propTypes = {
  title: PropTypes.string.isRequired,
  titleWidth: PropTypes.number.isRequired,
  styles: PropTypes.objectOf(PropTypes.shape).isRequired,
  size: PropTypes.number,
};

PDFSubtitle.defaultProps = {
  size: 18,
};

export default PDFSubtitle;
