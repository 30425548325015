/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { graphql } from 'gatsby';

// Lib UI components

// Local UI components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import Main from '../page-components/SelectProject/Main';

// Style
import '../page-styles/SelectProject.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function SelectProject() {
  /* ********************************** HOOKS ********************************* */

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="Select project" />
      <div className="select-project">
        <Main />
      </div>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["SelectProject", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default SelectProject;
