/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents
import { Text, View, Image } from '@react-pdf/renderer';

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style

// Assets
import siteLogo from '../../../images/logo.png';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */
function PDFHeader({ styles, name, sourceName }) {
  /* ******************************** RENDERING ******************************* */
  return (
    <View style={{ ...styles.header, ...styles.w100 }}>
      <View style={{ ...styles.flexCol, ...styles.w50 }}>
        <Text style={{ ...styles.whiteText, ...styles.size(18) }}>
          Fiche d&apos;investissement
        </Text>
        <Text
          style={{
            ...styles.uppercase,
            ...styles.bold,
            ...styles.yellowText,
            ...styles.size(18),
            marginTop: 5,
          }}
        >
          {`${sourceName ? `${sourceName} --> ` : ''}${name}`}
        </Text>
      </View>
      <View style={{ ...styles.flexCol, width: '10%' }}>
        <Image source={siteLogo} style={styles.logo} />
      </View>
    </View>
  );
}

export default PDFHeader;

PDFHeader.propTypes = {
  styles: PropTypes.objectOf(PropTypes.shape).isRequired,
  name: PropTypes.string.isRequired,
  sourceName: PropTypes.string.isRequired,
};

PDFHeader.defaultProps = {};
