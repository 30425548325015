/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// UI lib comoponents

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function PickProject({ selectedProject, setSelectedProject }) {
  /* ******************************** HOOKS ******************************* */
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  // localization
  const { t } = useTranslation();
  const { description, btnlist, errorMessage, btn } = t('pickProject', {
    returnObjects: true,
  });

  /* ******************************** VARIABLES ******************************* */

  /* ******************************** CALLBACKS ******************************* */
  function handleSelectProject(id, name, variation) {
    setShowErrorMessage(false);
    setSelectedProject({
      ...selectedProject,
      project: { id, name, variation },
    });
  }

  function handleClick(e) {
    e.preventDefault();
    if (selectedProject?.project?.id === undefined) {
      setShowErrorMessage(true);
      return;
    }
    if (selectedProject?.project?.name === undefined) {
      setShowErrorMessage(true);
    }

    setSelectedProject({ ...selectedProject, step: 2 });
  }

  /* ******************************** RENDER HELPERS ******************************* */
  function List() {
    return btnlist.map(({ id, text, variation }) => {
      const isActive = selectedProject?.project?.id === id;
      return (
        <div className="pick-project-btn-item" key={id}>
          <button
            type="button"
            className={`${isActive ? 'active' : ''}`}
            onClick={() => handleSelectProject(id, text, variation)}
          >
            {text}
          </button>
        </div>
      );
    });
  }

  /* ******************************** RENDERING ******************************* */
  return (
    <div className="pick-project">
      <div className="pick-project-description">{description}</div>
      <div className="pick-project-btn">
        <List />
        {showErrorMessage ? (
          <span className="error-msg">{errorMessage}</span>
        ) : null}
      </div>
      <button type="submit" className="submit-button" onClick={handleClick}>
        {btn}
      </button>
    </div>
  );
}

PickProject.propTypes = {
  selectedProject: PropTypes.objectOf(
    PropTypes.shape,
    PropTypes.shape,
    PropTypes.number,
  ).isRequired,
  setSelectedProject: PropTypes.func.isRequired,
};

PickProject.defaultProps = {};

export default PickProject;
