/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { BlobProvider } from '@react-pdf/renderer';
import { useI18next } from 'gatsby-plugin-react-i18next';
import axios from 'axios';

// UI lib comoponents
import { Col, Row } from 'react-grid-system';

// Local UI components
import DynamicImage from '../../../shared/UIKit/DynamicImage';

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';
import { API_SHEET } from '../../../shared/ApIs';
import MyPDF from '../../../shared/UIKit/PDF';
import Spinner from '../../../shared/UIKit/Spinner';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Recap({
  selectedProject,
  setSelectedProject,
  sourceCountry,
  targetCountry,
}) {
  /* ********************************** HOOKS ********************************* */

  const { language } = useI18next();

  const locale = language === undefined ? 'fr' : language;
  /* ******************************** RENDERING ******************************* */
  const [loadingPDF, setLoadingPDF] = useState(true);
  const [sheetData, setSheetData] = useState();
  async function getSheet() {
    try {
      setLoadingPDF(true);
      const { data } = await axios.get(
        API_SHEET(sourceCountry, targetCountry, locale),
      );
      setSheetData(data?.data[0]);
      setLoadingPDF(false);
    } catch (error) {
      setLoadingPDF(false);
    }
  }

  useEffect(() => {
    getSheet();
  }, []);

  const document = sheetData && <MyPDF sheetData={sheetData} />;

  // localization
  const { t } = useTranslation();
  const { goBackText, cards } = t('recap', {
    returnObjects: true,
  });

  /* ******************************** CALLBACKS ******************************* */
  function handleGoBack() {
    setSelectedProject({ ...selectedProject, step: 2 });
  }

  /* ******************************** RENDER HELPERS ******************************* */

  function Cards() {
    return cards.map(({ id, icon, name, seeMoreText, type }) => (
      <Fragment key={id}>
        {type === 'sheet' ? (
          sheetData && (
            <BlobProvider document={document}>
              {({ url }) => {
                return (
                  url && (
                    <Col key={id} sm={12} lg={6}>
                      <div className="project-recap-card">
                        <div className="project-recap-card-icon-container">
                          <DynamicImage
                            path="selectProjectRecap"
                            fileName={icon}
                            alt=""
                            className="project-recap-card__icon"
                          />
                        </div>
                        <p className="project-recap-card__name">{name}</p>
                        <a href={url} rel="noreferrer" target="_blank">
                          <button
                            type="button"
                            className="project-recap-card__button"
                          >
                            {seeMoreText}
                          </button>
                        </a>
                      </div>
                    </Col>
                  )
                );
              }}
            </BlobProvider>
          )
        ) : (
          <BlobProvider document={document}>
            {({ url }) => {
              return (
                url && (
                  <Col key={id} sm={12} lg={6}>
                    <div className="project-recap-card">
                      <div className="project-recap-card-icon-container">
                        <DynamicImage
                          path="selectProjectRecap"
                          fileName={icon}
                          alt=""
                          className="project-recap-card__icon"
                        />
                      </div>
                      <p className="project-recap-card__name">{name}</p>
                      <button
                        type="button"
                        className="project-recap-card__button"
                      >
                        {seeMoreText}
                      </button>
                    </div>
                  </Col>
                )
              );
            }}
          </BlobProvider>
        )}
      </Fragment>
    ));
  }
  /* ******************************** RENDERING ******************************* */

  if (loadingPDF) return <Spinner />;

  return (
    <div className="project-recap">
      <Row
        align="center"
        justify="center"
        gutterWidth={60}
        className="project-recap-cards"
      >
        <Cards />
      </Row>
      <button
        type="button"
        className="project-recap__button"
        onClick={handleGoBack}
      >
        {goBackText}
      </button>
    </div>
  );
}

Recap.propTypes = {
  selectedProject: PropTypes.objectOf(
    PropTypes.shape,
    PropTypes.shape,
    PropTypes.number,
  ).isRequired,
  setSelectedProject: PropTypes.func.isRequired,
  sourceCountry: PropTypes.string.isRequired,
  targetCountry: PropTypes.string.isRequired,
};

Recap.defaultProps = {};

export default Recap;
