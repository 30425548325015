/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents
import { Text, View } from '@react-pdf/renderer';

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style

// Assets

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */
function PDFFooter({ pages = 2, page = 1, styles }) {
  /* ******************************** RENDERING ******************************* */
  return (
    <View
      style={{
        backgroundColor: '#141320',
        width: '100%',
        ...styles.flexCenter,
        padding: '5px 0',
        marginTop: 'auto',
      }}
      fixed
    >
      <Text style={{ ...styles.whiteText, ...styles.size(10) }}>
        <Text style={{ ...styles.bold, ...styles.size(12) }}>{page}</Text>
        {` /${pages}`}
      </Text>
    </View>
  );
}

PDFFooter.propTypes = {
  styles: PropTypes.objectOf(PropTypes.shape).isRequired,
  pages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
};

export default PDFFooter;
