/* eslint-disable no-restricted-globals */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents
import { Text, View } from '@react-pdf/renderer';
import PDFSubtitle from './PDFSubtitle';

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style

// Assets

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */
function PDFTable({ styles, table, title, titleWidth }) {
  function isFloat(number) {
    return /^[+-]?\d+(.\d+)?$/.test(number);
  }
  function formatIfNumber(text) {
    if (text === null || text === undefined) return '-';
    if (Number.isNaN(Number(text))) {
      return text;
    }

    return Number(text)
      ?.toLocaleString('fr-FR', {
        useGrouping: true,
      })
      ?.replace(/(\u{202F})/gu, '\u00A0');
  }
  /* ******************************** RENDERING ******************************* */
  return (
    <View style={styles.flexCol}>
      {title?.length > 0 && (
        <PDFSubtitle styles={styles} title={title} titleWidth={titleWidth} />
      )}
      {table.headers?.length > 0 && (
        <>
          <View
            style={{
              ...styles.flexRow,
              ...styles.w100,
              ...styles.yellowBg,
              padding: 5,
            }}
          >
            <View style={styles.w50} />
            {table.headers?.map((text) => (
              <Text
                key={text}
                style={{ width: '12.5%', ...styles.bold, ...styles.size(10) }}
              >
                {text}
              </Text>
            ))}
          </View>
        </>
      )}
      {table.rows.map((row) => {
        return (
          <View
            style={{
              ...styles.flexRow,
              ...styles.w100,
              padding: 5,
              marginTop: 10,
            }}
          >
            {row.map((text, index) => {
              let hasPercent = false;
              if (index > 0) {
                if (text !== null && text !== undefined) {
                  if (row[0]?.includes('%')) {
                    hasPercent = true;
                  }
                }
              }
              let rowStyles = {
                width: '12.5%',
                paddingRight: 10,
                ...styles.size(10),
              };

              if (index === 0) {
                rowStyles = {
                  ...rowStyles,
                  ...styles.medium,
                  ...styles.w50,
                  ...styles.size(9),
                  paddingRight: '4%',
                };
              }

              return (
                <Text key={text} style={rowStyles}>
                  {isFloat(text) === false || !text.includes('.')
                    ? formatIfNumber(text)
                    : formatIfNumber(Number.parseFloat(text).toFixed(2))}
                  {hasPercent && (!isNaN(Number(text)) || isFloat(text))
                    ? '%'
                    : ''}
                </Text>
              );
            })}
          </View>
        );
      })}
    </View>
  );
}

PDFTable.propTypes = {
  title: PropTypes.string.isRequired,
  titleWidth: PropTypes.number.isRequired,
  styles: PropTypes.objectOf(PropTypes.shape).isRequired,
  table: PropTypes.objectOf(PropTypes.shape).isRequired,
};

PDFTable.defaultProps = {};

export default PDFTable;
